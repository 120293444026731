/**
 * Why is this file in vanilla JS?
 *
 * Because we import it in places like `next.config.js` to define some redirects
 * and rewrites. It is still strictly-typed though, via TSDoc:
 *
 * https://tsdoc.org/
 */

/**
 * @type {import("./locales.types").LaunchedLocale[]}
 */
export const launchedLocales = [
  {
    launched: true,
    localeString: "at-de",
    market: "at",
    lang: "de",
    dir: "ltr",
    flagCode: "AT",
    countryDisplayName: "Austria",
    webtranslateitLocale: "de-AT",
    faqSegment: "fakten",
  },
  {
    launched: true,
    localeString: "gb-en",
    market: "gb",
    lang: "en",
    dir: "ltr",
    flagCode: "GB",
    countryDisplayName: "United Kingdom",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  // JULY 31
  {
    launched: true,
    localeString: "it-it",
    market: "it",
    lang: "it",
    dir: "ltr",
    flagCode: "IT",
    countryDisplayName: "Italy",
    webtranslateitLocale: "it-IT",
    faqSegment: "red-bull-domande-risposte",
  },
  {
    launched: true,
    localeString: "de-de",
    market: "de",
    lang: "de",
    dir: "ltr",
    flagCode: "DE",
    countryDisplayName: "Germany",
    webtranslateitLocale: "de-DE",
    faqSegment: "fakten",
  },
  {
    launched: true,
    localeString: "in-en",
    market: "in",
    lang: "en",
    dir: "ltr",
    flagCode: "IN",
    countryDisplayName: "India",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  // AUGUST 12
  {
    launched: true,
    localeString: "ch-de",
    market: "ch",
    lang: "de",
    dir: "ltr",
    flagCode: "CH",
    countryDisplayName: "Switzerland (de)",
    webtranslateitLocale: "de-CH",
    faqSegment: "fakten",
  },
  {
    launched: true,
    localeString: "ie-en",
    market: "ie",
    lang: "en",
    dir: "ltr",
    flagCode: "IE",
    countryDisplayName: "Ireland",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "nl-nl",
    market: "nl",
    lang: "nl",
    dir: "ltr",
    flagCode: "NL",
    countryDisplayName: "Netherlands",
    webtranslateitLocale: "nl-NL",
    faqSegment: "informatie",
  },
  {
    launched: true,
    localeString: "ro-ro",
    market: "ro",
    lang: "ro",
    dir: "ltr",
    flagCode: "RO",
    countryDisplayName: "Romania",
    webtranslateitLocale: "ro-RO",
    faqSegment: "intrebari-si-raspunsuri",
  },
  // AUGUST 13
  {
    launched: true,
    localeString: "pt-pt",
    market: "pt",
    lang: "pt",
    dir: "ltr",
    flagCode: "PT",
    countryDisplayName: "Portugal",
    webtranslateitLocale: "pt-PT",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "my-en",
    market: "my",
    lang: "en",
    dir: "ltr",
    flagCode: "MY",
    countryDisplayName: "Malaysia",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "gr-el",
    market: "gr",
    lang: "el",
    dir: "ltr",
    flagCode: "GR",
    countryDisplayName: "Greece",
    webtranslateitLocale: "el-GR",
    faqSegment: "synitheis-erotiseis",
  },
  {
    launched: true,
    localeString: "ar-es",
    market: "ar",
    lang: "es",
    dir: "ltr",
    flagCode: "AR",
    countryDisplayName: "Argentina",
    webtranslateitLocale: "es-AR",
    faqSegment: "red-bull-preguntas-y-respuestas",
  },
  // AUGUST 16
  {
    launched: true,
    localeString: "za-en",
    market: "za",
    lang: "en",
    dir: "ltr",
    flagCode: "ZA",
    countryDisplayName: "South Africa",
    webtranslateitLocale: "en-ZA",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "mx-es",
    market: "mx",
    lang: "es",
    dir: "ltr",
    flagCode: "MX",
    countryDisplayName: "Mexico",
    webtranslateitLocale: "es-MX",
    faqSegment: "todo-sobre-red-bull",
  },
  // AUGUST 20
  {
    launched: true,
    localeString: "ch-fr",
    market: "ch",
    lang: "fr",
    dir: "ltr",
    flagCode: "CH",
    countryDisplayName: "Switzerland (fr)",
    webtranslateitLocale: "fr-CH",
    faqSegment: "informations",
  },
  {
    launched: true,
    localeString: "es-es",
    market: "es",
    lang: "es",
    dir: "ltr",
    flagCode: "ES",
    countryDisplayName: "Spain",
    webtranslateitLocale: "es",
    faqSegment: "informacion-red-bull",
  },
  // AUGUST 21
  {
    launched: true,
    localeString: "nz-en",
    market: "nz",
    lang: "en",
    dir: "ltr",
    flagCode: "NZ",
    countryDisplayName: "New Zealand",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "cz-cs",
    market: "cz",
    lang: "cs",
    dir: "ltr",
    flagCode: "CZ",
    countryDisplayName: "Czech Republic",
    webtranslateitLocale: "cs-CZ",
    faqSegment: "fakta",
  },
  {
    launched: true,
    localeString: "pl-pl",
    market: "pl",
    lang: "pl",
    dir: "ltr",
    flagCode: "PL",
    countryDisplayName: "Poland",
    webtranslateitLocale: "pl-PL",
    faqSegment: "fakty",
  },
  {
    launched: true,
    localeString: "au-en",
    market: "au",
    lang: "en",
    dir: "ltr",
    flagCode: "AU",
    countryDisplayName: "Australia",
    webtranslateitLocale: "en-AU",
    faqSegment: "qa",
  },
  // AUGUST 22
  {
    launched: true,
    localeString: "fr-fr",
    market: "fr",
    lang: "fr",
    dir: "ltr",
    flagCode: "FR",
    countryDisplayName: "France",
    webtranslateitLocale: "fr-FR",
    faqSegment: "informations",
  },
  {
    launched: true,
    localeString: "no-no",
    market: "no",
    lang: "no",
    dir: "ltr",
    flagCode: "NO",
    countryDisplayName: "Norway",
    webtranslateitLocale: "nb-NO",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "ru-ru",
    market: "ru",
    lang: "ru",
    dir: "ltr",
    flagCode: "RU",
    countryDisplayName: "Russia",
    webtranslateitLocale: "ru-RU",
    faqSegment: "informaziya",
  },
  // AUGUST 26
  {
    launched: true,
    localeString: "tr-tr",
    market: "tr",
    lang: "tr",
    dir: "ltr",
    flagCode: "TR",
    countryDisplayName: "Turkey",
    webtranslateitLocale: "tr-TR",
    faqSegment: "sorular",
  },
  // AUGUST 28
  {
    launched: true,
    localeString: "us-en",
    market: "us",
    lang: "en",
    dir: "ltr",
    flagCode: "US",
    countryDisplayName: "USA",
    webtranslateitLocale: "en-US",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "int-en",
    market: "int",
    lang: "en",
    dir: "ltr",
    flagCode: "Worldwide",
    countryDisplayName: "International",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "be-nl",
    market: "be",
    lang: "nl",
    dir: "ltr",
    flagCode: "BE",
    countryDisplayName: "Belgium (nl)",
    webtranslateitLocale: "nl-BE",
    faqSegment: "informatie",
  },
  {
    launched: true,
    localeString: "be-fr",
    market: "be",
    lang: "fr",
    dir: "ltr",
    flagCode: "BE",
    countryDisplayName: "Belgium (fr)",
    webtranslateitLocale: "fr-BE",
    faqSegment: "informations",
  },
  {
    launched: true,
    localeString: "sk-sk",
    market: "sk",
    lang: "sk",
    dir: "ltr",
    flagCode: "SK",
    countryDisplayName: "Slovakia",
    webtranslateitLocale: "sk-SK",
    faqSegment: "qas",
  },
  // SEPTEMBER 02
  {
    launched: true,
    localeString: "az-az",
    market: "az",
    lang: "az",
    dir: "ltr",
    flagCode: "AZ",
    countryDisplayName: "Azerbaijan",
    webtranslateitLocale: "az-AZ",
    faqSegment: "qas",
  },
  {
    launched: true,
    localeString: "ee-et",
    market: "ee",
    lang: "et",
    dir: "ltr",
    flagCode: "EE",
    countryDisplayName: "Estonia",
    webtranslateitLocale: "et-EE",
    faqSegment: "qa",
  },
  // SEPTEMBER 05
  {
    launched: true,
    localeString: "fi-fi",
    market: "fi",
    lang: "fi",
    dir: "ltr",
    flagCode: "FI",
    countryDisplayName: "Finland",
    webtranslateitLocale: "fi-FI",
    faqSegment: "kysymykset-ja-vastaukset",
  },
  {
    launched: true,
    localeString: "ua-uk",
    market: "ua",
    lang: "uk",
    dir: "ltr",
    flagCode: "UA",
    countryDisplayName: "Ukraine",
    webtranslateitLocale: "uk-UA",
    faqSegment: "faq",
  },
  // SEPTEMBER 12
  {
    launched: true,
    localeString: "dk-da",
    market: "dk",
    lang: "da",
    dir: "ltr",
    flagCode: "DK",
    countryDisplayName: "Denmark",
    webtranslateitLocale: "da-DK",
    faqSegment: "fakta",
  },
  {
    launched: true,
    localeString: "ba-bs",
    market: "ba",
    lang: "bs",
    dir: "ltr",
    flagCode: "BA",
    countryDisplayName: "Bosnia and Herzegovina",
    webtranslateitLocale: "bs-BA",
    faqSegment: "pitanja-i-odgovori",
  },
  // SEPTEMBER 19
  {
    launched: true,
    localeString: "se-en",
    market: "se",
    lang: "en",
    dir: "ltr",
    flagCode: "SE",
    countryDisplayName: "Sweden (en)",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "se-sv",
    market: "se",
    lang: "sv",
    dir: "ltr",
    flagCode: "SE",
    countryDisplayName: "Sweden (sv)",
    webtranslateitLocale: "sv-SE",
    faqSegment: "fakta",
  },
  {
    launched: true,
    localeString: "pe-es",
    market: "pe",
    lang: "es",
    dir: "ltr",
    flagCode: "PE",
    countryDisplayName: "Peru",
    webtranslateitLocale: "es-PE",
    faqSegment: "informacion-red-bull",
  },
  {
    launched: true,
    localeString: "lv-lv",
    market: "lv",
    lang: "lv",
    dir: "ltr",
    flagCode: "LV",
    countryDisplayName: "Latvia",
    webtranslateitLocale: "lv-LV",
    faqSegment: "qa",
  },
  // SEPTEMBER 26
  {
    launched: true,
    localeString: "jp-ja",
    market: "jp",
    lang: "ja",
    dir: "ltr",
    flagCode: "JP",
    countryDisplayName: "Japan",
    webtranslateitLocale: "ja-JP",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "hr-hr",
    market: "hr",
    lang: "hr",
    dir: "ltr",
    flagCode: "HR",
    countryDisplayName: "Croatia",
    webtranslateitLocale: "hr-HR",
    faqSegment: "cinjenice",
  },
  {
    launched: true,
    localeString: "kz-ru",
    market: "kz",
    lang: "ru",
    dir: "ltr",
    flagCode: "KZ",
    countryDisplayName: "Kazakhstan",
    webtranslateitLocale: "kk-KZ",
    faqSegment: "jii-qoyilatin-suraqtar",
  },
  // OCTOBER 10
  {
    launched: true,
    localeString: "hu-hu",
    market: "hu",
    lang: "hu",
    dir: "ltr",
    flagCode: "HU",
    countryDisplayName: "Hungary",
    webtranslateitLocale: "hu-HU",
    faqSegment: "kerdezz-felelek",
  },
  // OCTOBER 16
  {
    launched: true,
    localeString: "cl-es",
    market: "cl",
    lang: "es",
    dir: "ltr",
    flagCode: "CL",
    countryDisplayName: "Chile",
    webtranslateitLocale: "es-CL",
    faqSegment: "red-bull-preguntas-frecuentes",
  },
  // OCTOBER 24
  {
    launched: true,
    localeString: "tw-zh",
    market: "tw",
    lang: "zh",
    dir: "ltr",
    flagCode: "TW",
    countryDisplayName: "Taiwan",
    webtranslateitLocale: "zh-TW",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "hk-zh",
    market: "hk",
    lang: "zh",
    dir: "ltr",
    flagCode: "HK",
    countryDisplayName: "Hong Kong",
    webtranslateitLocale: "zh-HK",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "ca-en",
    market: "ca",
    lang: "en",
    dir: "ltr",
    flagCode: "CA",
    countryDisplayName: "Canada (en)",
    webtranslateitLocale: "en-CA",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "sg-en",
    market: "sg",
    lang: "en",
    dir: "ltr",
    flagCode: "SG",
    countryDisplayName: "Singapore",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "co-es",
    market: "co",
    lang: "es",
    dir: "ltr",
    flagCode: "CO",
    countryDisplayName: "Colombia",
    webtranslateitLocale: "es-CO",
    faqSegment: "informacion-red-bull",
  },
  {
    launched: true,
    localeString: "kr-ko",
    market: "kr",
    lang: "ko",
    dir: "ltr",
    flagCode: "KR",
    countryDisplayName: "Korea",
    webtranslateitLocale: "ko-KR",
    faqSegment: "jajuhaneun-jilmun",
  },
  {
    launched: true,
    localeString: "mea-en",
    market: "mea",
    lang: "en",
    dir: "ltr",
    flagCode: "Worldwide",
    countryDisplayName: "Middle East & Africa (en)",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "mea-ar",
    market: "mea",
    lang: "ar",
    dir: "rtl",
    flagCode: "Worldwide",
    countryDisplayName: "Middle East & Africa (ar)",
    webtranslateitLocale: "ar",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "ae-en",
    market: "ae",
    lang: "en",
    dir: "ltr",
    flagCode: "AE",
    countryDisplayName: "United Arab Emirates (en)",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "ae-ar",
    market: "ae",
    lang: "ar",
    dir: "rtl",
    flagCode: "AE",
    countryDisplayName: "United Arab Emirates (ar)",
    webtranslateitLocale: "ar",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "si-sl",
    market: "si",
    lang: "sl",
    dir: "ltr",
    flagCode: "SI",
    countryDisplayName: "Slovenia",
    webtranslateitLocale: "sl-SI",
    faqSegment: "dejstva",
  },
  // 1 April 2020
  {
    launched: true,
    localeString: "ca-fr",
    market: "ca",
    lang: "fr",
    dir: "ltr",
    flagCode: "CA",
    countryDisplayName: "Canada (fr)",
    webtranslateitLocale: "fr-CA",
    faqSegment: "q-et-r",
  },
  // 29 June 2020
  {
    launched: true,
    localeString: "ng-en",
    market: "ng",
    lang: "en",
    dir: "ltr",
    flagCode: "NG",
    countryDisplayName: "Nigeria",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "ke-en",
    market: "ke",
    lang: "en",
    dir: "ltr",
    flagCode: "KE",
    countryDisplayName: "Kenya",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "ph-en",
    market: "ph",
    lang: "en",
    dir: "ltr",
    flagCode: "PH",
    countryDisplayName: "Philippines",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "bg-bg",
    market: "bg",
    lang: "bg",
    dir: "ltr",
    flagCode: "BG",
    countryDisplayName: "Bulgaria",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "dz-fr",
    market: "dz",
    lang: "fr",
    dir: "ltr",
    flagCode: "DZ",
    countryDisplayName: "Algeria",
    webtranslateitLocale: "fr-FR",
    faqSegment: "informations",
  },
  // added Juli 17 2020
  {
    launched: true,
    localeString: "al-sq",
    market: "al",
    lang: "sq",
    dir: "ltr",
    flagCode: "AL",
    countryDisplayName: "Albania",
    webtranslateitLocale: "sq",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "eg-en",
    market: "eg",
    lang: "en",
    dir: "ltr",
    flagCode: "EG",
    countryDisplayName: "Egypt",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "pk-en",
    market: "pk",
    lang: "en",
    dir: "ltr",
    flagCode: "PK",
    countryDisplayName: "Pakistan",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "br-pt",
    market: "br",
    lang: "pt",
    dir: "ltr",
    flagCode: "BR",
    countryDisplayName: "Brazil",
    webtranslateitLocale: "pt-BR",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "lt-lt",
    market: "lt",
    lang: "lt",
    dir: "ltr",
    flagCode: "LT",
    countryDisplayName: "Lithuania",
    webtranslateitLocale: "lt-LT",
    faqSegment: "duk",
  },
  {
    launched: true,
    localeString: "rs-sr",
    market: "rs",
    lang: "sr",
    dir: "ltr",
    flagCode: "RS",
    countryDisplayName: "Serbia",
    webtranslateitLocale: "sr",
    faqSegment: "pitanja-i-odgovori",
  },
  {
    launched: true,
    localeString: "mk-mk",
    market: "mk",
    lang: "mk",
    dir: "ltr",
    flagCode: "MK",
    countryDisplayName: "North Macedonia",
    webtranslateitLocale: "mk-MK",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "id-id",
    market: "id",
    lang: "id",
    dir: "ltr",
    flagCode: "ID",
    countryDisplayName: "Indonesia",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "kw-en",
    market: "kw",
    lang: "en",
    dir: "ltr",
    flagCode: "KW",
    countryDisplayName: "Kuwait (en)",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "kw-ar",
    market: "kw",
    lang: "ar",
    dir: "rtl",
    flagCode: "KW",
    countryDisplayName: "Kuwait (ar)",
    webtranslateitLocale: "ar",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "cn-zh",
    market: "cn",
    lang: "zh",
    dir: "ltr",
    flagCode: "CN",
    countryDisplayName: "China",
    webtranslateitLocale: "zh-CN",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "car-es",
    market: "car",
    lang: "es",
    dir: "ltr",
    flagCode: "Worldwide",
    countryDisplayName: "Caribbean (es)",
    webtranslateitLocale: "es-CAR",
    faqSegment: "informacion-red-bull",
  },
  {
    launched: true,
    localeString: "qa-en",
    market: "qa",
    lang: "en",
    dir: "ltr",
    flagCode: "QA",
    countryDisplayName: "Qatar (en)",
    webtranslateitLocale: "en",
    faqSegment: "qa",
  },
  {
    launched: true,
    localeString: "qa-ar",
    market: "qa",
    lang: "ar",
    dir: "rtl",
    flagCode: "QA",
    countryDisplayName: "Qatar (ar)",
    webtranslateitLocale: "ar",
    faqSegment: "qa",
  },
];

/**
 * @type {import("./locales.types").ToBeLaunchedLocale[]}
 */
export const toBeLaunchedLocales = [
  {
    launched: false,
    countryDisplayName: "Angola",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "AO",
  },
  {
    launched: false,
    countryDisplayName: "Bolivia",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "BO",
  },
  {
    launched: false,
    countryDisplayName: "Costa Rica (en)",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "CR",
  },
  {
    launched: false,
    countryDisplayName: "Costa Rica (fr)",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "CR",
  },
  {
    launched: false,
    countryDisplayName: "Cyprus",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "CY",
  },
  {
    launched: false,
    countryDisplayName: "Dominican Republic",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "DO",
  },
  {
    launched: false,
    countryDisplayName: "Georgia",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "GE",
  },
  {
    launched: false,
    countryDisplayName: "Israel",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "IL",
  },
  {
    launched: false,
    countryDisplayName: "Jamaica",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "JM",
  },
  {
    launched: false,
    countryDisplayName: "Kosovo",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "XK",
  },
  {
    launched: false,
    countryDisplayName: "Maldives",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "MV",
  },
  {
    launched: false,
    countryDisplayName: "Panama (es)",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "PA",
  },
  {
    launched: false,
    countryDisplayName: "Panama (en)",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "PA",
  },
  {
    launched: false,
    countryDisplayName: "Saudi Arabia",
    baseWebUrl: "https://energydrink-sa.redbull.com/en",
    flagCode: "SA",
  },
  {
    launched: false,
    countryDisplayName: "Sri Lanka",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "LK",
  },
  {
    launched: false,
    countryDisplayName: "Thailand",
    baseWebUrl: "https://energydrink.redbull.com",
    flagCode: "TH",
  },
];

export const locales = [...launchedLocales, ...toBeLaunchedLocales].sort(
  (a, b) => a.countryDisplayName.localeCompare(b.countryDisplayName),
);

/**
 * @satisfies {Partial<import("./locales.types").LaunchedLocale>}
 */
export const nextLocale = {
  localeString: "ne-xt",
  dir: "ltr",
  lang: "en",
  market: "next",
  faqSegment: "qa",
};
